import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, FormFeedback, Input, FormGroup, Label } from 'reactstrap'

const styles = {
  label: { fontWeight: 400, fontStyle: 'italic' },
}
const DetailInputs = props => {
  const [hasTyped, setHasTyped] = useState(false)
  return (
    <Form>
      <FormGroup>
        <Label style={styles.label} for="title">
          Title
        </Label>
        <Input
          invalid={hasTyped && props.title === ''}
          onChange={params => {
            props.setTitle(params.target.value)
            setHasTyped(true)
          }}
          value={props.title}
        />
        <FormFeedback>Enter an Escalation title.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label style={styles.label} for="description">
          Description
        </Label>
        <Input
          type="textarea"
          onChange={params => {
            props.setDescription(params.target.value)
            setHasTyped(true)
          }}
          value={props.description}
        />
      </FormGroup>
    </Form>
  )
}

DetailInputs.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  setTitle: PropTypes.func,
  setDescription: PropTypes.func,
}

export default DetailInputs
