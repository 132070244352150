import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import DetailInputs from 'containers/Escalation/DetailInputs'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'

const EditDetailsModal = props => {
  const [updatedTitle, setUpdatedTitle] = useState(props.title)
  const [updatedDescription, setUpdatedDescription] = useState(
    props.description
  )
  const [shouldClose, setShouldClose] = useState(false)

  useEffect(() => {
    if (!props.saving && shouldClose) {
      props.close()
    }
  }, [props.saving])

  const handleUpdate = () => {
    if (
      updatedTitle !== props.title ||
      props.description !== updatedDescription
    ) {
      setShouldClose(true)
      props.update({
        details: {
          title: updatedTitle,
          description: updatedDescription,
        },
      })
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.close}>
      <ModalHeader>Update Details</ModalHeader>
      <ModalBody>
        <DetailInputs
          title={updatedTitle}
          setTitle={setUpdatedTitle}
          description={updatedDescription}
          setDescription={setUpdatedDescription}
        />
      </ModalBody>
      <ModalFooter>
        <TextLink color="primary" onClick={props.close}>
          Cancel
        </TextLink>
        <Button
          disabled={
            (updatedTitle === props.title &&
              props.description === updatedDescription) ||
            props.saving
          }
          onClick={handleUpdate}
          color="primary"
        >
          <Icon fa name="save" style={{ marginRight: '0.5em' }} />
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

EditDetailsModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  saving: PropTypes.bool,
  close: PropTypes.func,
  update: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  allSwimlanes: selectSwimlanes,
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect)(EditDetailsModal)
