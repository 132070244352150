import React from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Hoverable from 'components/Hoverable'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import BotWidget from 'components/BotWidget'
import UserWidget from 'components/UserWidget'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { compose } from 'redux'
import { startCase, camelCase } from 'lodash'
import { FormattedMessage } from 'react-intl'
import {
  selectBots,
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import { TitleShimmer } from 'components/ShimmerLoader'
import Tooltip from 'components/Tooltip'
import messages from './messages'

const RuleDisplay = props => {
  const getMissingTooltip = (srn, type) => {
    if (type === 'bot') {
      return (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
          }}
        >
          <Icon
            style={{ marginRight: '0.25em' }}
            fa
            name="exclamation-circle"
            color="red"
          />
          <div
            style={{ fontWeight: '400', fontStyle: 'italic' }}
          >{`Unavailable - ${srn}`}</div>
        </div>
      )
    }

    return (
      <Tooltip
        trigger={'hover'}
        anchor={
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
            }}
          >
            <Icon
              style={{ marginRight: '0.25em' }}
              fa
              name="exclamation-circle"
              color="red"
            />
            <UserWidget srn={srn} table />
          </div>
        }
        tooltipContent={
          type === 'user' ? (
            <FormattedMessage {...messages.missingUser} />
          ) : type === 'disabled' ? (
            <FormattedMessage {...messages.disabledUser} />
          ) : (
            <FormattedMessage {...messages.missingBot} />
          )
        }
      />
    )
  }

  const getLink = () => {
    if (props.rule.get('actionType') === 'ESCALATE_TO_BOT') {
      if (props.bots.get('isLoading')) {
        return <TitleShimmer width={200} />
      }
      const bot = props.bots
        .get('data')
        .find(
          bot =>
            bot.get('srn') === props.rule.getIn(['escalateToBot', 'botSrn'])
        )
      if (bot) {
        return <BotWidget srn={bot.get('srn')} table />
      } else {
        return getMissingTooltip(
          props.rule.getIn(['escalateToBot', 'botSrn']),
          bot
        )
      }
    }
    if (props.rule.get('actionType') === 'ASSIGN_USER') {
      if (props.usersLoading) {
        return <TitleShimmer width={200} />
      }
      const user = props.users.get(props.rule.getIn(['assignUser', 'userSrn']))
      if (user) {
        if (user.get('isActive')) {
          return (
            <UserWidget
              srn={props.rule.getIn(['assignUser', 'userSrn'])}
              table
            />
          )
        } else {
          return getMissingTooltip(
            props.rule.getIn(['assignUser', 'userSrn']),
            'disabled'
          )
        }
      } else {
        return getMissingTooltip(
          props.rule.getIn(['assignUser', 'userSrn']),
          'user'
        )
      }
    } else if (props.rule.get('actionType') === 'ASSIGN_ROLE') {
      return (
        <span>
          {startCase(camelCase(props.rule.getIn(['assignRole', 'roleType'])))}
        </span>
      )
    }
  }

  const getTypeMessage = () => {
    if (props.rule.get('actionType') === 'ESCALATE_TO_BOT') {
      return ' escalate to applicable bot '
    }
    if (props.rule.get('actionType') === 'ASSIGN_USER') {
      return ' escalate to user '
    } else if (props.rule.get('actionType') === 'ASSIGN_ROLE') {
      return ' escalate to role '
    }
  }

  const buildTimeMessage = () => {
    let text = ' after '
    if (props.rule.get('triggerAfter') < 86400) {
      text += `${Math.floor(props.rule.get('triggerAfter') / 3600)} hour(s)`
    } else {
      text += `${Math.floor(props.rule.get('triggerAfter') / 86400)} day(s)`
    }
    return text
  }

  const message = buildTimeMessage() + getTypeMessage()

  return (
    <div>
      <Hoverable
        hoverStyle={{
          backgroundColor: props.theme.highlight,
          cursor: 'default',
        }}
        renderContent={({ hovered }) => (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                display: 'grid',
                gridTemplateColumns: 'auto auto 1fr',
                columnGap: '0.5em',
              }}
            >
              <div>
                If &nbsp;<b>not resolved</b>
              </div>

              <div>{message}</div>
              <div
                style={{
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {getLink()}
              </div>
            </div>

            {hovered && props.canEdit && (
              <BorderlessButton color="primary" onClick={props.delete}>
                <Icon fa name="trash" />
              </BorderlessButton>
            )}
          </div>
        )}
      />
    </div>
  )
}

RuleDisplay.propTypes = {
  rule: ImmutableProptypes.map,
  bots: ImmutableProptypes.map,
  users: ImmutableProptypes.map,
  usersLoading: PropTypes.bool,
  delete: PropTypes.func,
  canEdit: PropTypes.bool,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  users: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
  bots: selectBots,
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect, themeable)(RuleDisplay)
