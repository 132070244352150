import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Form, FormGroup, Label } from 'reactstrap'
import Select from 'react-select'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import { fromJS } from 'immutable'
import permissionChecker from 'containers/PermissionChecker'

const findLabel = (srn, swimlanes) => {
  const swimlane = swimlanes.find(sl => sl.get('srn') === srn)
  if (swimlane) {
    return swimlane.get('title')
  } else {
    return srn
  }
}

const styles = {
  label: {
    fontWeight: 400,
    fontStyle: 'italic',
  },
}

const SwimlaneInputs = props => {
  const [selectedSwimlanes, setSelectedSwimlanes] = useState(
    props.swimlanes
      ? props.swimlanes
          .map(swombo => ({
            label: findLabel(swombo.get('swimlaneSRN'), props.allSwimlanes),
            value: swombo.get('swimlaneSRN'),
          }))
          .toJS()
      : []
  )

  useEffect(() => {
    props.setSwimlanes(
      fromJS(
        selectedSwimlanes
          ? selectedSwimlanes.map(sw => ({ swimlaneSRN: sw.value }))
          : null
      )
    )
  }, [selectedSwimlanes])

  const handleChangeSwimlanes = params => {
    setSelectedSwimlanes(params)
  }

  const getOptions = () => {
    return props.allSwimlanes
      .toList()
      .filter(swim => {
        return props.userHasPermission({
          permissionName: 'assign.escalations',
          swimlanes: [swim.get('srn')],
        })
      })
      .map(swombo => ({
        value: swombo.get('srn'),
        label: swombo.get('title'),
      }))
      .toJS()
  }

  return (
    <Form>
      <FormGroup>
        <Label style={styles.label} for="title">
          {selectedSwimlanes && selectedSwimlanes.length < 1
            ? `No Swimlanes Assigned`
            : `Swimlanes Assigned`}
        </Label>
        <Select
          value={selectedSwimlanes}
          onChange={handleChangeSwimlanes}
          isMulti
          options={getOptions()}
        />
      </FormGroup>
    </Form>
  )
}

SwimlaneInputs.propTypes = {
  allSwimlanes: ImmutableProptypes.map,
  setSwimlanes: PropTypes.func,
  userHasPermission: PropTypes.func.isRequired,
  swimlanes: ImmutableProptypes.list,
}

const mapStateToProps = createStructuredSelector({
  allSwimlanes: selectSwimlanes,
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect, permissionChecker)(SwimlaneInputs)
