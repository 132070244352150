import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import RuleInputs from 'containers/Escalation/RuleInputs'

const EditFiltersModal = props => {
  const [updatedRules, setUpdatedRules] = useState(List())
  const [shouldClose, setShouldClose] = useState(false)

  useEffect(() => {
    if (!props.saving && shouldClose) {
      props.close()
    }
  }, [props.saving])

  const getIsDifferent = () => {
    let isDiff = false
    if (props.rules) {
      props.rules.forEach(rule => {
        if (
          !updatedRules.find(updated => {
            if (
              updated.get('actionType') === rule.get('actionType') &&
              updated.get('triggerAfter') === rule.get('triggerAfter')
            ) {
              if (updated.get('actionType') === 'ASSIGN_USER') {
                return (
                  updated.getIn(['assignUser', 'userSrn']) ===
                  rule.getIn(['assignUser', 'userSrn'])
                )
              } else if (updated.get('actionType') === 'ESCALATE_TO_BOT') {
                return (
                  updated.getIn(['escalateToBot', 'botSrn']) ===
                  rule.getIn(['escalateToBot', 'botSrn'])
                )
              } else if (updated.get('actionType') === 'ASSIGN_ROLE') {
                return (
                  updated.getIn(['assignRole', 'roleType']) ===
                  rule.getIn(['assignRole', 'roleType'])
                )
              }
              return true
            } else {
              return false
            }
          })
        ) {
          isDiff = true
        }
      })
    }

    updatedRules.forEach(updated => {
      if (
        props.rules &&
        !props.rules.find(rule => {
          if (
            updated.get('actionType') === rule.get('actionType') &&
            updated.get('triggerAfter') === rule.get('triggerAfter')
          ) {
            if (updated.get('actionType') === 'ASSIGN_USER') {
              return (
                updated.getIn(['assignUser', 'userSrn']) ===
                rule.getIn(['assignUser', 'userSrn'])
              )
            } else if (updated.get('actionType') === 'ESCALATE_TO_BOT') {
              return (
                updated.getIn(['escalateToBot', 'botSrn']) ===
                rule.getIn(['escalateToBot', 'botSrn'])
              )
            } else if (updated.get('actionType') === 'ASSIGN_ROLE') {
              return (
                updated.getIn(['assignRole', 'roleType']) ===
                rule.getIn(['assignRole', 'roleType'])
              )
            }
            return true
          } else {
            return false
          }
        })
      ) {
        isDiff = true
      } else if (!props.rules) {
        isDiff = true
      }
    })
    return isDiff
  }

  const organizeAndSave = () => {
    const add = []
    const remove = []
    if (props.rules) {
      props.rules.forEach(rule => {
        if (
          !updatedRules.find(updated => {
            if (
              updated.get('actionType') === rule.get('actionType') &&
              updated.get('triggerAfter') === rule.get('triggerAfter')
            ) {
              if (updated.get('actionType') === 'ASSIGN_USER') {
                return (
                  updated.getIn(['assignUser', 'userSrn']) ===
                  rule.getIn(['assignUser', 'userSrn'])
                )
              } else if (updated.get('actionType') === 'ESCALATE_TO_BOT') {
                return (
                  updated.getIn(['escalateToBot', 'botSrn']) ===
                  rule.getIn(['escalateToBot', 'botSrn'])
                )
              } else if (updated.get('actionType') === 'ASSIGN_ROLE') {
                return (
                  updated.getIn(['assignRole', 'roleType']) ===
                  rule.getIn(['assignRole', 'roleType'])
                )
              }
              return true
            } else {
              return false
            }
          })
        ) {
          remove.push(rule.get('srn'))
        }
      })
    }

    updatedRules.forEach(updated => {
      if (
        props.rules &&
        !props.rules.find(rule => {
          if (
            updated.get('actionType') === rule.get('actionType') &&
            updated.get('triggerAfter') === rule.get('triggerAfter')
          ) {
            if (updated.get('actionType') === 'ASSIGN_USER') {
              return (
                updated.getIn(['assignUser', 'userSrn']) ===
                rule.getIn(['assignUser', 'userSrn'])
              )
            } else if (updated.get('actionType') === 'ESCALATE_TO_BOT') {
              return (
                updated.getIn(['escalateToBot', 'botSrn']) ===
                rule.getIn(['escalateToBot', 'botSrn'])
              )
            } else if (updated.get('actionType') === 'ASSIGN_ROLE') {
              return (
                updated.getIn(['assignRole', 'roleType']) ===
                rule.getIn(['assignRole', 'roleType'])
              )
            }
            return true
          } else {
            return false
          }
        })
      ) {
        add.push(updated.toJS())
      } else if (!props.rules) {
        add.push(updated.toJS())
      }
    })

    setShouldClose(true)
    props.update({
      rules: {
        add,
        remove,
      },
    })
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.close} size="lg">
      <ModalHeader>Update Filters</ModalHeader>
      <ModalBody>
        <RuleInputs
          canEdit={true}
          rules={props.rules}
          setRules={setUpdatedRules}
        />
      </ModalBody>
      <ModalFooter>
        <TextLink color="primary" onClick={props.close}>
          Cancel
        </TextLink>
        <Button
          disabled={props.saving || !getIsDifferent()}
          onClick={organizeAndSave}
          color="primary"
        >
          <Icon fa name="save" style={{ marginRight: '0.5em' }} />
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

EditFiltersModal.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  saving: PropTypes.bool,
  rules: ImmutableProptypes.list,
  update: PropTypes.func,
}

export default EditFiltersModal
