import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import SwimlaneInputs from 'containers/Escalation/SwimlaneInputs'
import ImmutableProptypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import qs from 'querystring'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'

import messages from './messages'

const EditSwimlanesModal = props => {
  const [updatedSwimlanes, setUpdatedSwimlanes] = useState(List())
  const [shouldClose, setShouldClose] = useState(false)

  useEffect(() => {
    if (!props.saving && shouldClose) {
      if (!props.errorInvalidSwimlanes) {
        props.close()
      }
    }
  }, [props.saving])

  const getIsDifferent = () => {
    if (!props.swimlanes && updatedSwimlanes) {
      return true
    }
    if (props.swimlanes && !updatedSwimlanes) {
      return true
    }
    if (!props.swimlanes && !updatedSwimlanes) {
      return false
    }

    let isDiff = false
    props.swimlanes.forEach(swombo => {
      if (
        !updatedSwimlanes.find(
          s => swombo.get('swimlaneSRN') === s.get('swimlaneSRN')
        )
      ) {
        isDiff = true
      }
    })
    updatedSwimlanes.forEach(swombini => {
      if (
        !props.swimlanes.find(
          s => s.get('swimlaneSRN') === swombini.get('swimlaneSRN')
        )
      ) {
        isDiff = true
      }
    })
    return isDiff
  }

  const organizeAndSave = () => {
    const add = []
    const remove = []

    if (updatedSwimlanes) {
      if (!props.swimlanes) {
        add.push(...updatedSwimlanes.map(sl => sl.get('swimlaneSRN')))
      } else {
        props.swimlanes.forEach(swombo => {
          if (
            !updatedSwimlanes.find(
              s => swombo.get('swimlaneSRN') === s.get('swimlaneSRN')
            )
          ) {
            remove.push(swombo.get('srn'))
          }
        })
        updatedSwimlanes.forEach(swombini => {
          if (
            !props.swimlanes.find(
              s => s.get('swimlaneSRN') === swombini.get('swimlaneSRN')
            )
          ) {
            add.push(swombini.get('swimlaneSRN'))
          }
        })
      }
    } else {
      remove.push(...props.swimlanes.map(sl => sl.get('srn')))
    }

    setShouldClose(true)
    props.update({
      swimlanes: {
        add,
        remove,
      },
    })
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.close} size="lg">
      <ModalHeader>Update Swimlane Assignments</ModalHeader>
      <ModalBody>
        <SwimlaneInputs
          swimlanes={props.swimlanes}
          setSwimlanes={setUpdatedSwimlanes}
        />
        <div>
          {props.errorInvalidSwimlanes && (
            // render the error message that user would make custom ticket
            // configurations invalid because of the swimlane removals
            <Fragment>
              <DynamicFormattedMessage
                {...messages.invalidSwimlaneEditErrorMessage}
              />
              <br />
              <br />
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto',
                }}
              >
                {props.errorInvalidSwimlanes
                  .get('tickets')
                  .toJS()
                  .map(({ srn, title }) => {
                    const requiredSwimlanes = props.errorInvalidSwimlanes
                      .getIn(['requiredSwimlanes', srn])
                      .map(srn => {
                        return props.allSwimlanes
                          .valueSeq()
                          .find(swimbini => swimbini.get('srn') === srn)
                      })
                      .filter(swimmy => swimmy)
                      .toJS()
                    return (
                      <Fragment key={`customticketerrorlink_${srn}`}>
                        <span>
                          <b>{title}: </b>
                          <TextLink
                            to={{
                              pathname: '/App/TicketDetails',
                              search: qs.stringify({ srn }),
                            }}
                            newTab
                          >
                            {srn}
                          </TextLink>
                        </span>
                        <div style={{ textAlign: 'right' }}>
                          <em>
                            ({'required: '}
                            {requiredSwimlanes
                              .map(swimbini => swimbini.title)
                              .join(', ')}
                            )
                          </em>
                        </div>
                      </Fragment>
                    )
                  })}
              </div>
            </Fragment>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <TextLink color="primary" onClick={props.close}>
          Cancel
        </TextLink>
        <Button
          disabled={props.saving || !getIsDifferent()}
          onClick={organizeAndSave}
          color="primary"
        >
          <Icon fa name="save" style={{ marginRight: '0.5em' }} />
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

EditSwimlanesModal.propTypes = {
  allSwimlanes: ImmutablePropTypes.map,
  swimlanes: ImmutableProptypes.list,
  isOpen: PropTypes.bool,
  saving: PropTypes.bool,
  close: PropTypes.func,
  update: PropTypes.func,
  errorInvalidSwimlanes: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
}

export default EditSwimlanesModal
