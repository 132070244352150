/*
 * AppLayout Messages
 *
 * This contains all the text for the AppLayout component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  allEscalationTitle: {
    id: 'app.containers.Escalation.allEscalationTitle',
    defaultMessage: 'All Escalation Schemes',
  },
  addEscalation: {
    id: 'app.containers.Escalation.addEscalation',
    defaultMessage: 'Add Escalation',
  },
  missingUser: {
    id: 'app.containers.Escalation.missingUser',
    defaultMessage:
      'User data failed to load or no longer exists. If user has been deleted the escalation rule will fail.',
  },
  disabledUser: {
    id: 'app.containers.Escalation.disabledUser',
    defaultMessage:
      'User is disabled. If user is disabled the escalalation rule will fail.',
  },
  missingBot: {
    id: 'app.containers.Escalation.missingBot',
    defaultMessage:
      'Bot data failed to load or no longer exists. If bot has been deleted the escalation rule will fail.',
  },
})
