import { defineMessages } from 'react-intl'

export default defineMessages({
  invalidSwimlaneEditErrorMessage: {
    id: 'app.containers.EscalationDetails.invalidSwimlaneEditErrorMessage',
    defaultMessage:
      'The changes have not been saved because an invalid state was ' +
      'detected. The follwing custom tickets are assigned to this ' +
      'escalations and have swimlanes assigned which are being removed. ' +
      'This will cause the tickets not to be escalated. Please remove the ' +
      'escalation filters for the following tickets to proceed:',
  },
})
