import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import FilterInputs from 'containers/Escalation/FilterInputs'

const EditFiltersModal = props => {
  const [updatedFilters, setUpdatedFilters] = useState(List())
  const [shouldClose, setShouldClose] = useState(false)

  useEffect(() => {
    if (!props.saving && shouldClose) {
      props.close()
    }
  }, [props.saving])

  const getIsDifferent = () => {
    let isDiff = false

    if (props.filters) {
      if (
        props.filters.getIn([0, 'allKeys'], false) &&
        updatedFilters.isEmpty()
      ) {
        isDiff = true
      } else {
        props.filters.forEach(filter => {
          if (
            !updatedFilters.find(updated => {
              if (filter.get('allKeys')) {
                return (
                  updated.get('ticketType').toLowerCase() ===
                  filter.get('ticketType').toLowerCase()
                )
              } else if (filter.get('controlFrameworkSrn')) {
                return (
                  filter.get('controlFrameworkSrn') &&
                  filter.get('controlFrameworkSrn') ===
                    updated.get('controlFrameworkSrn')
                )
              } else {
                return (
                  updated.get('ticketType').toLowerCase() ===
                    filter.get('ticketType').toLowerCase() &&
                  updated.getIn(['ticketKey', 'value']) ===
                    filter.get('ticketKey')
                )
              }
            })
          ) {
            isDiff = true
          }
        })
      }
    }

    if (updatedFilters) {
      if (updatedFilters.getIn([0, 'allKeys'], false) && !props.filters) {
        isDiff = true
      } else {
        updatedFilters.forEach(updated => {
          if (
            !props.filters ||
            !props.filters.find(filter => {
              if (updated.get('allKeys')) {
                return (
                  filter.get('ticketType').toLowerCase() ===
                  updated.get('ticketType').toLowerCase()
                )
              } else if (updated.get('controlFrameworkSrn')) {
                return (
                  filter.get('controlFrameworkSrn') ===
                  updated.get('controlFrameworkSrn')
                )
              } else {
                return (
                  filter.get('ticketType').toLowerCase() ===
                    updated.get('ticketType').toLowerCase() &&
                  filter.get('ticketKey') ===
                    updated.getIn(['ticketKey', 'value'])
                )
              }
            })
          ) {
            isDiff = true
          }
        })
      }
    }
    return isDiff
  }

  const organizeAndSave = () => {
    const add = []
    const remove = []
    if (props.filters) {
      props.filters.forEach(filter => {
        if (
          !updatedFilters.find(updated => {
            if (filter.get('allKeys')) {
              return (
                updated.get('ticketType').toLowerCase() ===
                filter.get('ticketType').toLowerCase()
              )
            } else if (filter.get('controlFrameworkSrn')) {
              return (
                filter.get('controlFrameworkSrn') ===
                updated.get('controlFrameworkSrn')
              )
            } else {
              return (
                updated.get('ticketType').toLowerCase() ===
                  filter.get('ticketType').toLowerCase() &&
                updated.getIn(['ticketKey', 'value']) ===
                  filter.get('ticketKey')
              )
            }
          })
        ) {
          remove.push(filter.get('srn'))
        }
      })
    }

    updatedFilters.forEach(updated => {
      if (props.filters) {
        if (
          !props.filters.find(filter => {
            if (updated.get('allKeys')) {
              return (
                filter.get('ticketType').toLowerCase() ===
                updated.get('ticketType').toLowerCase()
              )
            } else if (updated.get('controlFrameworkSrn')) {
              return (
                filter.get('controlFrameworkSrn') ===
                updated.get('controlFrameworkSrn')
              )
            } else {
              return (
                filter.get('ticketType').toLowerCase() ===
                  updated.get('ticketType').toLowerCase() &&
                filter.get('ticketKey') ===
                  updated.getIn(['ticketKey', 'value'])
              )
            }
          })
        ) {
          add.push(
            updated
              .set('ticketKey', updated.getIn(['ticketKey', 'value'], null))
              .toJS()
          )
        }
      } else {
        add.push(
          updated
            .set('ticketKey', updated.getIn(['ticketKey', 'value'], null))
            .toJS()
        )
      }
    })

    setShouldClose(true)
    props.update({
      filters: {
        add,
        remove,
      },
    })
  }

  useEffect(() => {
    setUpdatedFilters(List())
  }, [props.filters])

  return (
    <Modal isOpen={props.isOpen} toggle={props.close} size="lg">
      <ModalHeader>Update Filters</ModalHeader>
      <ModalBody>
        <FilterInputs filters={props.filters} setFilters={setUpdatedFilters} />
      </ModalBody>
      <ModalFooter>
        <TextLink color="primary" onClick={props.close}>
          Cancel
        </TextLink>
        <Button
          disabled={props.saving || !getIsDifferent()}
          onClick={organizeAndSave}
          color="primary"
        >
          <Icon fa name="save" style={{ marginRight: '0.5em' }} />
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

EditFiltersModal.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  saving: PropTypes.bool,
  filters: ImmutableProptypes.list,
  update: PropTypes.func,
}

export default EditFiltersModal
