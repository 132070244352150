import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import {
  Form,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { fromJS, List } from 'immutable'
import Select from 'react-select'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import Hoverable from 'components/Hoverable'
import ReactTooltip from 'react-tooltip'
import {
  selectEscalationFilterOptions,
  selectEscalationFilterOptionsLoading,
} from 'containers/EscalationData/selectors'
import {
  selectPolicies,
  selectControlGroups,
  selectFetchingPolicies,
  selectFetchingControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import { fetchEscalationOptions } from 'containers/EscalationData/actions'
import { startCase } from 'lodash'
import { TitleShimmer } from 'components/ShimmerLoader'

const FILTER_TYPES = {
  policy: 'Control Policy',
  controlframework: 'Control Framework',
  property: 'Property Change',
  access: 'Access',
  activity: 'Activity',
  custom: 'Custom Ticket',
  all: 'Enable All',
}

const filterTypeOptions = Object.keys(FILTER_TYPES).map(key => ({
  value: key,
  label: FILTER_TYPES[key],
}))

// Access -> Has permission to, Actionable by
const accessOptions = [
  { value: 'hasPermissionTo', label: 'Has Permission To' },
  { value: 'actionableBy', label: 'Actionable By' },
]
// Activity -> Accessed from, Accessed using
const activityOptions = [
  { value: 'accessedFrom', label: 'Accessed From' },
  { value: 'accessedUsing', label: 'Accessed Using' },
]

const getKeyText = keys => {
  return `Key: ${keys.get('label')}`
}

const FilterInputs = props => {
  const getKeyOption = (type, key, controlFrameworkSrn) => {
    const lowerType = type ? type.toLowerCase() : ''
    switch (lowerType) {
      case 'policy':
        return {
          value: key,
          label: controlFrameworkSrn
            ? props.controlFrameworks.getIn(
                [controlFrameworkSrn, 'title'],
                controlFrameworkSrn
              )
            : props.policies.getIn([key, 'title'], key),
        }
      case 'property':
        return {
          value: key,
          label: startCase(key),
        }
      case 'access':
        return {
          value: key,
          label: accessOptions.find(op => op.value === key)
            ? accessOptions.find(op => op.value === key).label
            : '',
        }
      case 'activity':
        return {
          value: key,
          label: activityOptions.find(op => op.value === key)
            ? activityOptions.find(op => op.value === key).label
            : '',
        }
      case 'custom':
        return {
          value: key,
          label: key,
        }
    }
  }

  const selectStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        // backgroundColor:
        //   data.value === 'ALL' ? props.theme.success : 'inherited',
        color: data.value === 'all' ? props.theme.success : 'inherited',
        ':hover': {
          backgroundColor: '#B2D4FF',
        },
      }
    },
  }

  const buildOptionsOnMount = filters => {
    return fromJS(
      filters.toJS().map(filter => ({
        allKeys: filter.allKeys,
        allTypes: filter.allTypes,
        ticketKey: getKeyOption(
          filter.ticketType,
          filter.ticketKey,
          filter.controlFrameworkSrn
        ),
        ticketType: {
          value: filter.ticketType,
          label:
            FILTER_TYPES[
              filter.ticketType ? filter.ticketType.toLowerCase() : ''
            ],
        },
        controlFrameworkSrn: filter.controlFrameworkSrn,
      }))
    )
  }

  useEffect(() => {
    if (props.properties.isEmpty() && !props.loadingProperties) {
      props.fetchEscalationOptions()
    }
  }, [])

  useEffect(() => {
    if (
      !policyOptions &&
      !props.policies.isEmpty() &&
      !props.fetchingPolicies
    ) {
      setPolicyOptions(
        props.policies
          .map(policy => ({
            value: policy.get('srn'),
            label: policy.get('title'),
          }))
          .toList()
          .toJS()
      )
    }
    if (
      !controlFrameworkOptions &&
      !props.controlFrameworks.isEmpty() &&
      !props.fetchingCFs
    ) {
      setControlFrameworkOptions(
        props.controlFrameworks
          .map(cf => ({
            value: cf.get('srn'),
            label: cf.get('title'),
          }))
          .toList()
          .toJS()
      )
    }
    if (
      !propertyOptions &&
      !props.properties.isEmpty() &&
      !props.loadingProperties
    ) {
      setPropertyOptions(
        props.properties
          .map(prop => ({
            value: prop.get('keyName'),
            label: startCase(prop.get('keyName')),
          }))
          .toJS()
      )
    }
  })

  const [selectedFilters, setSelectedFilters] = useState(
    buildOptionsOnMount(props.filters ? props.filters : List())
  )
  const [hasPolicyLabels, setHasPolicyLabels] = useState(false)
  const [hasCFLabels, setHasCFLabels] = useState(false)
  const [addNew, setAddNew] = useState(false)

  const [newType, setNewType] = useState(null)
  const [newKey, setNewKey] = useState('')
  const [newAllKeys, setNewAllKeys] = useState(false)

  const [policyOptions, setPolicyOptions] = useState(null)
  const [controlFrameworkOptions, setControlFrameworkOptions] = useState(null)
  const [propertyOptions, setPropertyOptions] = useState(null)

  const [allTicketWarningOpen, setAllTicketWarningOpen] = useState(false)

  useEffect(() => {
    if (
      (!props.policies.isEmpty() &&
        !props.fetchingPolicies &&
        !hasPolicyLabels) ||
      (!props.controlFrameworks.isEmpty() && !props.fetchingCFs && !hasCFLabels)
    ) {
      setSelectedFilters(
        selectedFilters.map(filter => {
          if (
            filter.getIn(['ticketType', 'value'], '').toLowerCase() === 'policy'
          ) {
            return filter.set(
              'ticketKey',
              fromJS(
                getKeyOption(
                  filter.getIn(['ticketType', 'value']),
                  filter.getIn(['ticketKey', 'value']),
                  filter.get('controlFrameworkSrn')
                )
              )
            )
          } else {
            return filter
          }
        })
      )
      if (
        !props.policies.isEmpty() &&
        !props.fetchingPolicies &&
        !hasPolicyLabels
      ) {
        setHasPolicyLabels(true)
      }
      if (
        !props.controlFrameworks.isEmpty() &&
        !props.fetchingCFs &&
        !hasCFLabels
      ) {
        setHasCFLabels(true)
      }
    }
  }, [props.fetchingPolicies, props.fetchingCFs])

  useEffect(() => {
    props.setFilters(
      fromJS(
        selectedFilters
          .map(filter => ({
            allKeys: filter.get('allKeys'),
            ticketKey: filter.get('ticketKey'),
            ticketType: startCase(filter.getIn(['ticketType', 'value'])),
            allTypes: filter.get('allTypes'),
            controlFrameworkSrn: filter.get('controlFrameworkSrn'),
          }))
          .toJS()
      )
    )
  }, [selectedFilters])

  useEffect(() => {
    if (newType && newType.value === 'all' && !allTicketWarningOpen) {
      setAddNew(false)
      clearNew()
      if (selectedFilters.isEmpty()) {
        addAllTicketType()
      } else {
        setAllTicketWarningOpen(true)
      }
    }
  }, [newType])

  const styles = {
    addNew: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    empty: {
      fontWeight: 400,
      fontStyle: 'italic',
    },
    topContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      marginBottom: '1em',
    },
    closeButton: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      marginTop: '1em',
      columnGap: '1em',
    },
    addContainer: {
      background: props.theme.neutralLight,
      borderRadius: '0.25em',
      padding: '0.5em',
      marginTop: '1em',
    },
  }

  const getAllKeyText = type => {
    const lowerType = type ? type.toLowerCase() : ''
    switch (lowerType) {
      case 'policy':
        return 'All Policies Selected...'
      case 'property':
        return 'All Properties Selected...'
      case 'access':
        return 'All Access Selected...'
      case 'activity':
        return 'All Activity Selected...'
      case 'all':
        return 'All Keys Selected...'
      default:
        return null
    }
  }

  const renderPolicySelect = () => {
    if (props.fetchingPolicies || !policyOptions) {
      return <TitleShimmer width={200} />
    }

    return (
      <div>
        <Label for="policyKey">Select Policy</Label>
        <Select
          name="policyKey"
          value={newAllKeys ? null : newKey}
          // isMulti
          placeholder={newAllKeys ? 'All Policies Selected...' : 'Select...'}
          isDisabled={newAllKeys}
          onChange={params => setNewKey(params)}
          options={policyOptions.filter(policy => {
            if (selectedFilters && !selectedFilters.isEmpty()) {
              if (
                selectedFilters.find(
                  filter =>
                    filter.getIn(['ticketKey', 'value']) === policy.value
                )
              ) {
                return false
              }
              return true
            } else {
              return true
            }
          })}
        />
      </div>
    )
  }

  const renderCFSelect = () => {
    if (props.fetchingCFs || !controlFrameworkOptions) {
      return <TitleShimmer width={200} />
    }

    let filteredOptions = null
    if (selectedFilters && !selectedFilters.isEmpty()) {
      filteredOptions = controlFrameworkOptions.filter(
        cf =>
          !selectedFilters.find(
            filter => filter.get('controlFrameworkSrn') === cf.value
          )
      )
    }

    return (
      <div>
        <Label for="cfKey">Select Control Framework</Label>
        <Select
          name="cfKey"
          value={newAllKeys ? null : newKey}
          // isMulti
          placeholder={
            newAllKeys ? 'All Control Frameworks Selected...' : 'Select...'
          }
          isDisabled={newAllKeys}
          onChange={params => setNewKey(params)}
          options={filteredOptions ? filteredOptions : controlFrameworkOptions}
        />
      </div>
    )
  }

  const renderPropertySelect = () => {
    if (props.loadingProperties || !propertyOptions) {
      return <TitleShimmer width={200} />
    }

    return (
      <div>
        <Label for="propertyKey">Select Property</Label>
        <Select
          name="propertyKey"
          value={newAllKeys ? null : newKey}
          // isMulti
          placeholder={newAllKeys ? 'All Properties Selected...' : 'Select...'}
          isDisabled={newAllKeys}
          onChange={params => setNewKey(params)}
          options={propertyOptions.filter(prop => {
            if (selectedFilters && !selectedFilters.isEmpty()) {
              if (
                selectedFilters.find(
                  filter => filter.getIn(['ticketKey', 'value']) === prop.value
                )
              ) {
                return false
              }
              return true
            } else {
              return true
            }
          })}
        />
      </div>
    )
  }

  const renderAccessSelect = () => {
    return (
      <div>
        <Label for="accessKey">Select Access</Label>
        <Select
          name="accessKey"
          value={newAllKeys ? null : newKey}
          // isMulti
          placeholder={newAllKeys ? 'All Access Selected...' : 'Select...'}
          isDisabled={newAllKeys}
          onChange={params => setNewKey(params)}
          options={accessOptions.filter(access => {
            if (selectedFilters && !selectedFilters.isEmpty()) {
              if (
                selectedFilters.find(
                  filter =>
                    filter.getIn(['ticketKey', 'value']) === access.value
                )
              ) {
                return false
              }
              return true
            } else {
              return true
            }
          })}
        />
      </div>
    )
  }

  const renderActivitySelect = () => {
    return (
      <div>
        <Label for="activityKey">Select Activity</Label>
        <Select
          name="activityKey"
          value={newAllKeys ? null : newKey}
          // isMulti
          placeholder={newAllKeys ? 'All Activity Selected...' : 'Select...'}
          isDisabled={newAllKeys}
          onChange={params => setNewKey(params)}
          options={activityOptions.filter(activity => {
            if (selectedFilters && !selectedFilters.isEmpty()) {
              if (
                selectedFilters.find(
                  filter =>
                    filter.getIn(['ticketKey', 'value']) === activity.value
                )
              ) {
                return false
              }
              return true
            } else {
              return true
            }
          })}
        />
      </div>
    )
  }

  const renderCustomSelect = () => {
    return <div>Custom</div>
  }

  const renderSelect = () => {
    const lowerType = newType.value ? newType.value.toLowerCase() : ''
    switch (lowerType) {
      case 'policy':
        return renderPolicySelect()
      case 'controlframework':
        return renderCFSelect()
      case 'property':
        return renderPropertySelect()
      case 'access':
        return renderAccessSelect()
      case 'activity':
        return renderActivitySelect()
      case 'custom':
        return renderCustomSelect()
      default:
        return null
    }
  }

  const clearNew = () => {
    setAddNew(false)
    setNewType(null)
    setNewKey('')
    setNewAllKeys(false)
  }

  const handleAddFilter = () => {
    let type = newType
    let cfSrn = null
    const lowerType = newType.value ? newType.value.toLowerCase() : ''
    if (lowerType === 'controlframework') {
      type = filterTypeOptions.find(
        op => op.value && op.value.toLowerCase() === 'policy'
      )
      cfSrn = newKey.value
    }

    clearNew()
    setSelectedFilters(
      selectedFilters.push(
        fromJS({
          ticketType: type,
          ticketKey: newAllKeys ? null : newKey,
          allKeys: newAllKeys,
          allTypes: false,
          controlFrameworkSrn: cfSrn,
        })
      )
    )
  }

  const getKeyLabel = () => {
    const lowerType = newType.value ? newType.value.toLowerCase() : ''
    switch (lowerType) {
      case 'policy':
        return 'All Policies'
      case 'property':
        return 'All Properties'
      case 'access':
        return 'All Access'
      case 'activity':
        return 'All Activities'
      case 'custom':
        return 'All Keys'
      default:
        return null
    }
  }

  const addAllTicketType = () => {
    clearNew()
    setAllTicketWarningOpen(false)
    setSelectedFilters(
      fromJS([
        {
          ticketType: { value: 'all', label: 'Enable All' },
          ticketKey: null,
          allKeys: true,
          allTypes: true,
          controlFrameworkSrn: null,
        },
      ])
    )
  }

  const handleRemoveFilter = index => {
    setSelectedFilters(selectedFilters.splice(index, 1))
  }

  return (
    <Form>
      <FormGroup>
        <div style={styles.topContainer}>
          <div style={styles.empty}>
            {selectedFilters.isEmpty()
              ? `No Filters Assigned`
              : `Filters Assigned`}
          </div>
          <div style={styles.addNew}>
            {!addNew && (
              <BorderlessButton
                onClick={() => {
                  selectedFilters.getIn([0, 'allTypes'], false)
                    ? handleRemoveFilter(0)
                    : setAddNew(true)
                }}
                color="primary"
              >
                {selectedFilters.getIn([0, 'allTypes'], false)
                  ? `Remove All Types`
                  : `New Filter`}
                <Icon
                  style={{ marginLeft: '0.25em' }}
                  fa
                  name={
                    selectedFilters.getIn([0, 'allTypes'], false)
                      ? 'times'
                      : 'plus'
                  }
                />
              </BorderlessButton>
            )}
          </div>
        </div>
        {selectedFilters.map((filter, index) => {
          return (
            <div style={{ marginBottom: '1em' }} key={`filterguy${index}`}>
              <Hoverable
                hoverStyle={{
                  backgroundColor: props.theme.highlight,
                  cursor: 'default',
                }}
                renderContent={({ hovered }) => (
                  <span data-tip data-for={`tooltip${index}`}>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '150px 1fr auto',
                      }}
                    >
                      <div style={{ fontWeight: 400 }}>
                        {filter.get('allTypes')
                          ? `All Ticket Types`
                          : filter.get('controlFrameworkSrn')
                          ? 'Control Framework'
                          : filter.getIn(['ticketType', 'label'])}
                      </div>
                      <div
                        style={{
                          fontStyle: 'italic',
                          whiteSpace: 'nowrap',
                          overflowX: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {(filter.get('controlFrameworkSrn') &&
                          (props.fetchingCFs ||
                            props.controlFrameworks.isEmpty())) ||
                        (filter
                          .getIn(['ticketType', 'value'], '')
                          .toLowerCase() === 'policy' &&
                          (props.fetchingPolicies ||
                            props.policies.isEmpty())) ? (
                          <TitleShimmer width={200} />
                        ) : filter.get('allKeys') ? (
                          getAllKeyText(filter.getIn(['ticketType', 'value']))
                        ) : (
                          <div>{filter.getIn(['ticketKey', 'label'])}</div>
                        )}
                      </div>
                      {hovered && (
                        <BorderlessButton
                          color="primary"
                          onClick={() => handleRemoveFilter(index)}
                        >
                          <Icon fa name="trash" />
                        </BorderlessButton>
                      )}
                    </div>
                  </span>
                )}
              />
              <ReactTooltip
                id={`tooltip${index}`}
                key={`tooltipKey${index}`}
                place="top"
                effect="solid"
                multiline={true}
                html={true}
              >
                {filter.get('allKeys')
                  ? getAllKeyText(filter.getIn(['ticketType', 'value']))
                  : getKeyText(filter.get('ticketKey'))}
              </ReactTooltip>
            </div>
          )
        })}

        {addNew && (
          <div style={styles.addContainer}>
            <div style={{ marginBottom: '1em' }}>
              <Label for="type">Ticket Type</Label>
              <Select
                name="type"
                value={newType}
                onChange={params => {
                  setNewKey('')
                  setNewAllKeys(false)
                  setNewType(params)
                }}
                options={filterTypeOptions}
                styles={selectStyles}
              />
            </div>
            {newType && (
              <div>
                {renderSelect()}
                {newType.value &&
                  newType.value.toLowerCase() !== 'controlframework' && (
                    <BorderlessButton
                      style={{ color: '#000000', marginTop: '1em' }}
                      onClick={() => setNewAllKeys(!newAllKeys)}
                    >
                      <Label for="allKeys">{getKeyLabel()}</Label>
                      <Input
                        style={{ marginLeft: '0.5em', marginTop: '6px' }}
                        name="allKeys"
                        checked={newAllKeys}
                        type="checkbox"
                        onChange={() => {}}
                      />
                    </BorderlessButton>
                  )}
              </div>
            )}
            <div style={styles.closeButton}>
              <Button
                disabled={!newAllKeys && newKey.length < 1}
                color="primary"
                onClick={handleAddFilter}
                size="sm"
              >
                Add
                <Icon style={{ marginLeft: '0.25em' }} fa name="plus" />
              </Button>
              <div style={{ display: 'flex' }}>
                <BorderlessButton onClick={clearNew} color="primary">
                  Close
                </BorderlessButton>
              </div>
            </div>
          </div>
        )}
        {allTicketWarningOpen && (
          <Modal toggle={() => setAllTicketWarningOpen(false)} isOpen={true}>
            <ModalHeader>
              Enabling all ticket types will remove all other filters.
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <TextLink
                  onClick={() => setAllTicketWarningOpen(false)}
                  color="primary"
                >
                  Cancel
                </TextLink>
                <Button
                  onClick={addAllTicketType}
                  style={{ marginLeft: '1em' }}
                  color="success"
                >
                  Confirm
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </FormGroup>
    </Form>
  )
}

FilterInputs.propTypes = {
  filters: ImmutableProptypes.list,
  policies: ImmutableProptypes.map,
  controlFrameworks: ImmutableProptypes.map,
  properties: ImmutableProptypes.list,
  loadingProperties: PropTypes.bool,
  fetchingPolicies: PropTypes.bool,
  fetchingCFs: PropTypes.bool,
  setFilters: PropTypes.func,
  fetchEscalationOptions: PropTypes.func,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  properties: selectEscalationFilterOptions,
  loadingProperties: selectEscalationFilterOptionsLoading,
  policies: selectPolicies,
  controlFrameworks: selectControlGroups,
  fetchingPolicies: selectFetchingPolicies,
  fetchingCFs: selectFetchingControlGroups,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchEscalationOptions,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable)(FilterInputs)
