import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { Form, Input, FormGroup, Label } from 'reactstrap'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { fromJS, List } from 'immutable'
import Select from 'react-select'
import Icon from 'components/Icon'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import { getBots } from 'containers/SonraiData/actions'
import {
  selectBots,
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import { fetchEscalationOptions } from 'containers/EscalationData/actions'
import { fetchPolicies } from 'containers/ControlFrameworkData/actions'
import RuleDisplay from './RuleDisplay'
import { ACTION_TYPES } from 'appConstants'
import { TitleShimmer } from 'components/ShimmerLoader'

const roleOptions = [
  { value: 'SYSTEM_ADMIN', label: 'System Admin' },
  { value: 'SWIMLANE_OWNER', label: 'Swimlane Owner' },
]

const unitOptions = [
  { label: 'Hour(s)', value: 'hour' },
  { label: 'Day(s)', value: 'day' },
]

const RuleInputs = props => {
  useEffect(() => {
    if (props.bots.get('data').isEmpty() && !props.bots.get('isLoading')) {
      props.getBots({ filters: {} })
    }
  }, [])

  useEffect(() => {
    if (botOptions.length < 1 && !props.bots.get('data').isEmpty()) {
      setBotOptions(
        props.bots
          .get('data')
          .map(bot => ({ label: bot.get('title'), value: bot.get('srn') }))
          .toJS()
      )
    }
  }, [props.bots])

  useEffect(() => {
    if (userOptions.length < 1 && !props.users.isEmpty()) {
      setUserOptions(
        props.users
          .map(user => ({
            label: user.get('name'),
            value: user.get('srn'),
          }))
          .toList()
          .toJS()
      )
    }
  })

  const [selectedRules, setSelectedRules] = useState(
    props.rules ? props.rules : List()
  )
  const [addNew, setAddNew] = useState(false)

  const [newResolvedTime, setNewResolvedTime] = useState(0)
  const [newResolvedTimeValid, setNewResolvedTimeValid] = useState(true)
  const [newResolvedUnit, setNewResolvedUnit] = useState(unitOptions[0])
  const [newActionType, setNewActionType] = useState(null)
  const [newActionTarget, setNewActionTarget] = useState(null)

  const [botOptions, setBotOptions] = useState([])
  const [userOptions, setUserOptions] = useState([])

  useEffect(() => {
    if (newResolvedTime) {
      if (/^\d+$/.test(newResolvedTime)) {
        setNewResolvedTimeValid(true)
      } else {
        setNewResolvedTimeValid(false)
      }
    }
  }, [newResolvedTime])

  const clearNew = () => {
    setAddNew(false)
    setNewResolvedTime(0)
    setNewResolvedUnit(unitOptions[0])
    setNewActionType(null)
    setNewActionTarget(null)
  }

  const renderSelect = () => {
    switch (newActionType.value) {
      case 'ESCALATE_TO_BOT':
        return renderBotSelect()
      case 'ASSIGN_USER':
        return renderUserSelect(props.usersLoading)
      case 'ASSIGN_ROLE':
        return renderRoleSelect()
    }
  }

  const renderBotSelect = () => {
    return (
      <div>
        <Label for="botSelect">For This Bot</Label>
        <Select
          controlHeight={200}
          name="botSelect"
          value={newActionTarget}
          onChange={setNewActionTarget}
          options={botOptions}
          placeholder="Select a Bot..."
        />
      </div>
    )
  }

  const renderUserSelect = loading => {
    return (
      <div>
        <Label for="userSelect">For This User</Label>
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TitleShimmer width={200} />
          </div>
        ) : (
          <Select
            name="userSelect"
            value={newActionTarget}
            onChange={setNewActionTarget}
            options={userOptions}
            placeholder="Select a User..."
          />
        )}
      </div>
    )
  }

  const renderRoleSelect = () => {
    return (
      <div>
        <Label for="roleSelect">For This Role</Label>
        <Select
          name="roleSelect"
          value={newActionTarget}
          onChange={setNewActionTarget}
          options={roleOptions}
          placeholder="Select a Role..."
        />
      </div>
    )
  }

  const styles = {
    addNew: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    empty: {
      fontWeight: 400,
      fontStyle: 'italic',
    },
    buttons: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      marginTop: '1em',
      columnGap: '1em',
    },
    addContainer: {
      background: props.theme.neutralLight,
      borderRadius: '0.25em',
      padding: '0.5em',
      marginTop: '1em',
      display: 'grid',
      gridTemplateRows: 'auto auto auto',
      rowGap: '1em',
    },
    topContainer: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      marginBottom: '1em',
    },
  }

  const handleRemoveRule = index => {
    setSelectedRules(selectedRules.splice(index, 1))
  }

  const handleAddRule = () => {
    let target = {}
    switch (newActionType.value) {
      case 'ESCALATE_TO_BOT':
        target.escalateToBot = {
          botSrn: newActionTarget.value,
        }
        break
      case 'ASSIGN_USER':
        target.assignUser = {
          userSrn: newActionTarget.value,
        }
        break
      case 'ASSIGN_ROLE':
        target.assignRole = {
          roleType: newActionTarget.value,
        }
        break
    }
    setSelectedRules(
      selectedRules.push(
        fromJS({
          actionType: newActionType.value,
          triggerAfter:
            newResolvedUnit.value === 'hour'
              ? newResolvedTime * 3600
              : newResolvedTime * 86400,
          ...target,
        })
      )
    )
    clearNew()
  }

  useEffect(() => {
    props.setRules(selectedRules)
  }, [selectedRules])

  useEffect(() => {
    if (!props.canEdit) {
      setSelectedRules(props.rules ? props.rules : List())
    }
  }, [props.rules])

  return (
    <Form>
      <FormGroup>
        <div style={styles.topContainer}>
          <div style={styles.empty}>
            {selectedRules.isEmpty() ? 'No Rules Assigned' : 'Rules Assigned'}
          </div>
          <div style={styles.addNew}>
            {!addNew && props.canEdit && (
              <BorderlessButton onClick={() => setAddNew(true)} color="primary">
                New Rule
                <Icon style={{ marginLeft: '0.25em' }} fa name="plus" />
              </BorderlessButton>
            )}
          </div>
        </div>
        {selectedRules
          .sortBy(rule => rule.get('triggerAfter'))
          .map((rule, index) => {
            return (
              <div style={{ marginBottom: '1em' }} key={`ruleguy${index}`}>
                {!props.bots.get('data').isEmpty() &&
                !props.bots.get('isLoading') ? (
                  <RuleDisplay
                    delete={() => handleRemoveRule(index)}
                    canEdit={props.canEdit}
                    rule={rule}
                  />
                ) : (
                  <TitleShimmer width={200} />
                )}
              </div>
            )
          })}

        {addNew && (
          <div style={styles.addContainer}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <div style={{ width: '80%' }}>
                <Label for="resolvedTime">
                  If <b>Not Resolved</b> After
                  {!newResolvedTimeValid && (
                    <span
                      style={{
                        color: 'red',
                        marginLeft: '1em',
                        fontSize: '0.8em',
                      }}
                    >
                      <span
                        style={{
                          verticalAlign: 'top',
                          display: 'inline-block',
                          lineHeight: '1.7em',
                          height: '15px',
                        }}
                      >
                        *
                      </span>
                      {`Enter valid integer.`}
                    </span>
                  )}
                </Label>
                <Input
                  name="resolvedTime"
                  value={newResolvedTime}
                  onChange={params => {
                    setNewResolvedTime(params.target.value)
                  }}
                  type="number"
                  defaultValue={0}
                />
              </div>
              <div style={{ width: '40%' }}>
                <Label for="resolvedUnit">&nbsp;</Label>
                <Select
                  name="resolvedUnit"
                  value={newResolvedUnit}
                  onChange={setNewResolvedUnit}
                  options={unitOptions}
                />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <div style={{ width: '80%' }}>
                <Label for="actionType">Perform This Action</Label>
                <Select
                  name="actionType"
                  value={newActionType}
                  onChange={params => {
                    setNewActionType(params)
                    setNewActionTarget(null)
                  }}
                  options={Object.keys(ACTION_TYPES).map(key => ({
                    value: key,
                    label: ACTION_TYPES[key],
                  }))}
                  placeholder="Select an Action..."
                />
              </div>
              <div style={{ width: '100%' }}>
                {newActionType && renderSelect()}
              </div>
            </div>

            <div style={styles.buttons}>
              <Button
                disabled={
                  !newActionType ||
                  !newActionTarget ||
                  !/^\d+$/.test(newResolvedTime)
                }
                color="primary"
                onClick={handleAddRule}
                size="sm"
              >
                Add
                <Icon style={{ marginLeft: '0.25em' }} fa name="plus" />
              </Button>
              <div style={{ display: 'flex' }}>
                <BorderlessButton onClick={clearNew} color="primary">
                  Close
                </BorderlessButton>
              </div>
            </div>
          </div>
        )}
      </FormGroup>
    </Form>
  )
}

RuleInputs.propTypes = {
  rules: ImmutableProptypes.list,
  usersLoading: PropTypes.bool,
  setRules: PropTypes.func,
  getBots: PropTypes.func,
  users: ImmutableProptypes.map,
  canEdit: PropTypes.bool,
  bots: ImmutableProptypes.map,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  users: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
  bots: selectBots,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchEscalationOptions,
      fetchPolicies,
      getBots,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable)(RuleInputs)
