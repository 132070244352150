import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { push } from 'connected-react-router'
import DataTable from 'components/DataTable'
import BorderedCard from 'components/BorderedCard'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import Button from 'components/Button'
import { List, Map } from 'immutable'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'

import qs from 'query-string'
import {
  selectEscalations,
  selectEscalationsLoading,
  selectUpdatingEscalation,
  selectUpdatingEscalationError,
  selectUpdatingEscalationInvalidSwimlaneForTickets,
} from 'containers/EscalationData/selectors'
import {
  selectSwimlanes,
  selectSwimlanesLoading,
} from 'containers/SonraiData/selectors'
import {
  selectFetchingPolicies,
  selectPolicies,
  selectControlGroups,
  selectFetchingControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import {
  getEscalations,
  updateEscalation,
  clearUpdateEscalationSwimlaneEditCustomTicketError,
} from 'containers/EscalationData/actions'
import SectionHeader from 'components/SectionHeader'
import { startCase, camelCase } from 'lodash'
import EditDetailsModal from './EditDetailsModal'
import EditFiltersModal from './EditFiltersModal'
import EditSwimlanesModal from './EditSwimlanesModal'
import EditRulesModal from './EditRulesModal'
import RuleInputs from 'containers/Escalation/RuleInputs'
import UserWidget from 'components/UserWidget'
import Expandable from 'components/Expandable'
import CenterContent from 'components/CenterContent'
import { Alert } from 'reactstrap'
import permissionChecker from 'containers/PermissionChecker'

export class EscalationDetails extends React.Component {
  constructor(props) {
    super(props)
    if (!props.loading && props.escalations.isEmpty()) {
      props.getEscalations()
    }

    this.styles = {
      container: {
        margin: '1em',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr',
        gridRowGap: '1em',
        height: '100%',
      },
      headerTop: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridColumnGap: '1em',
        marginBottom: '1em',
      },
      tableTop: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        marginBottom: '0.25em',
      },
      title: {
        fontWeight: 400,
      },
      description: {
        maxHeight: '150px',
        overflow: 'auto',
      },
      group: {
        marginBottom: '3em',
      },
    }
    this.state = {
      detailModalOpen: false,
      swimlaneModalOpen: false,
      filterModalOpen: false,
      ruleModalOpen: false,
      updatedSwimlanes: null,
    }
  }

  openDetailModal = () => {
    this.setState({ detailModalOpen: true })
  }

  closeDetailModal = () => {
    this.setState({ detailModalOpen: false })
  }

  openSwimlanelModal = () => {
    this.setState({ swimlaneModalOpen: true })
  }

  closeSwimlanelModal = () => {
    this.setState({ swimlaneModalOpen: false })
    this.props.clearErrorInvalidSwimlanes()
  }

  openFilterModal = () => {
    this.setState({ filterModalOpen: true })
  }

  closeFilterModal = () => {
    this.setState({ filterModalOpen: false })
  }

  openRuleModal = () => {
    this.setState({ ruleModalOpen: true })
  }

  closeRuleModal = () => {
    this.setState({ ruleModalOpen: false })
  }

  getSwimlaneData = swombosis => {
    return swombosis
      .filter(ass =>
        this.props.userHasPermission({
          permissionName: 'view.data',
          swimlanes: [ass.get('swimlaneSRN')],
        })
      )
      .map(assignment => {
        const sl = this.props.swimlanes.find(
          lane => lane.get('srn') === assignment.get('swimlaneSRN')
        )
        if (sl) {
          return { name: sl.get('title'), description: sl.get('description') }
        } else {
          return {
            name: `[ Swimlane Not Found ] ${assignment.get('swimlaneSRN')}`,
            description: 'Swimlane data failed to load or no longer exists.',
          }
        }
      })
      .toJS()
  }

  getKeyLabel = type => {
    const downCased = type ? type.toLowerCase() : ''
    switch (downCased) {
      case 'policy':
        return 'All Policies'
      case 'property':
        return 'All Properties'
      case 'access':
        return 'All Access'
      case 'activity':
        return 'All Activities'
      case 'custom':
        return 'All Keys'
      default:
        return 'All Keys'
    }
  }

  getFilterData = filters => {
    return filters
      .map(filter => {
        let keyParsed = ''
        let typeParsed = filter.get('allTypes')
          ? 'ALL TYPES'
          : filter.get('controlFrameworkSrn')
          ? 'Control Framework'
          : startCase(camelCase(filter.get('ticketType')))

        if (filter.get('allKeys')) {
          keyParsed = this.getKeyLabel(filter.get('ticketType'))
        } else {
          if (filter.get('ticketType').toLowerCase() === 'policy') {
            if (filter.get('controlFrameworkSrn')) {
              keyParsed = this.props.controlFrameworks.getIn(
                [filter.get('controlFrameworkSrn'), 'title'],
                `[ Control Framework Not Found ] ${filter.get(
                  'controlFrameworkSrn'
                )}`
              )
            } else {
              keyParsed = this.props.policies.getIn(
                [filter.get('ticketKey'), 'title'],
                `[ Policy Not Found ] ${filter.get('ticketKey')}`
              )
            }
          }
          // if it's for a custom ticket srn of a ticket, display that to user:
          else if (
            (filter.get('ticketKey') ?? '').match(
              /^srn:[a-zA-z]+::Ticket\/[a-f0-9-]+$/
            )
          ) {
            keyParsed = filter.get('ticketKey')
            typeParsed = 'Custom Ticket'
          }
          // otherwise it's probably a CRM or property ticket:
          else {
            keyParsed = startCase(filter.get('ticketKey'))
          }
        }
        return {
          ticketType: typeParsed,
          ticketKey: keyParsed,
        }
      })
      .toJS()
  }

  renderRules = rules => {
    return (
      <div style={{ padding: '1em' }}>
        <RuleInputs canEdit={false} setRules={() => {}} rules={rules} />
      </div>
    )
  }

  render() {
    if (this.props.loading || this.props.escalations.isEmpty()) {
      return (
        <CenterContent>
          <SquareLoadingAnimation />
        </CenterContent>
      )
    }

    const srn = qs.parse(this.props.location.search).srn
    const escalation = this.props.escalations.get(srn) || Map()

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.escalations',
    })

    const canAssign = this.props.userHasPermission({
      permissionName: 'assign.escalations',
    })

    const data = this.getFilterData(
      escalation.get('filters') ? escalation.get('filters') : List()
    )

    return (
      <div style={this.styles.container}>
        <Breadcrumb>
          <BreadcrumbItem
            style={{ cursor: 'pointer', color: this.props.theme.primary }}
            onClick={() => this.props.push({ pathname: '/App/Escalation' })}
          >
            All Escalations
          </BreadcrumbItem>
          <BreadcrumbItem> Escalation Details </BreadcrumbItem>
        </Breadcrumb>
        <BorderedCard style={{ height: '100%' }}>
          {this.props.updatingError && (
            <Alert color="danger">{this.props.updatingError}</Alert>
          )}
          <div style={this.styles.headerTop}>
            <SectionHeader>{escalation.get('title')}</SectionHeader>
            {canEdit && (
              <Button onClick={this.openDetailModal} outline color="primary">
                <Icon fa name="pencil-alt" style={{ marginRight: '0.5em' }} />
                Edit Details
              </Button>
            )}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              gridColumnGap: '1em',
            }}
          >
            <div style={this.styles.description}>
              <Expandable>{escalation.get('description')}</Expandable>
            </div>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
                gridColumnGap: '0.5em',
                alignItems: 'center',
              }}
            >
              <div style={{ fontWeight: 400 }}>Created By:</div>
              <UserWidget srn={escalation.get('createdBy')} />
            </div>
          </div>
        </BorderedCard>
        <BorderedCard
          style={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <div style={this.styles.group}>
            <div style={this.styles.tableTop}>
              <div style={this.styles.title}>Active for Swimlanes:</div>
              <div>
                {(canEdit || canAssign) && (
                  <TextLink onClick={this.openSwimlanelModal} color="primary">
                    <Icon
                      fa
                      name="pencil-alt"
                      style={{ marginRight: '0.5em' }}
                    />
                    Manage Swimlane Assignments
                  </TextLink>
                )}
              </div>
            </div>

            <div>
              {this.props.swimlanesLoading ? (
                <CenterContent>
                  <SquareLoadingAnimation />
                </CenterContent>
              ) : (
                <DataTable
                  fitAllRows
                  data={this.getSwimlaneData(
                    escalation.get('assignments')
                      ? escalation.get('assignments')
                      : List()
                  )}
                  autosize={false}
                  customColumnConfig={{
                    name: {
                      flex: 1,
                    },
                    description: {
                      flex: 1,
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div style={this.styles.group}>
            <div style={this.styles.tableTop}>
              <div style={this.styles.title}>
                For tickets matching at least one of:
              </div>
              <div>
                {canEdit && (
                  <TextLink onClick={this.openFilterModal} color="primary">
                    <Icon
                      fa
                      name="pencil-alt"
                      style={{ marginRight: '0.5em' }}
                    />
                    Manage Filters
                  </TextLink>
                )}
              </div>
            </div>
            <div style={this.styles.table}>
              {this.props.fetchingPolicies || this.props.fetchingCFs ? (
                <CenterContent>
                  <SquareLoadingAnimation />
                </CenterContent>
              ) : (
                <DataTable
                  fitAllRows
                  data={data}
                  autosize={false}
                  customColumnConfig={{
                    ticketType: {
                      flex: 1,
                    },
                    ticketKey: {
                      flex: 1,
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div style={this.styles.group}>
            <div style={this.styles.tableTop}>
              <div style={this.styles.title}>
                After a matching ticket is created:
              </div>
              <div>
                {canEdit && (
                  <TextLink onClick={this.openRuleModal} color="primary">
                    <Icon
                      fa
                      name="pencil-alt"
                      style={{ marginRight: '0.5em' }}
                    />
                    Manage Rules
                  </TextLink>
                )}
              </div>
            </div>

            <div style={this.styles.table}>
              {this.renderRules(escalation.get('rules'))}
            </div>
          </div>
        </BorderedCard>
        {this.state.detailModalOpen && (
          <EditDetailsModal
            title={escalation.get('title')}
            description={escalation.get('description')}
            update={params =>
              this.props.updateEscalation({
                schemeSrn: escalation.get('srn'),
                ...params,
              })
            }
            isOpen
            saving={this.props.updating}
            close={this.closeDetailModal}
          />
        )}
        {this.state.swimlaneModalOpen && (
          <EditSwimlanesModal
            escaltion={escalation}
            close={this.closeSwimlanelModal}
            isOpen
            errorInvalidSwimlanes={this.props.errorInvalidSwimlanes}
            allSwimlanes={this.props.swimlanes}
            swimlanes={
              escalation.get('assignments')
                ? escalation.get('assignments').filter(ass =>
                    this.props.userHasPermission({
                      permissionName: 'view.data',
                      swimlanes: [ass.get('swimlaneSRN')],
                    })
                  )
                : null
            }
            update={params =>
              this.props.updateEscalation({
                schemeSrn: escalation.get('srn'),
                ...params,
              })
            }
            saving={this.props.updating}
          />
        )}
        {this.state.filterModalOpen && (
          <EditFiltersModal
            close={this.closeFilterModal}
            isOpen
            update={params =>
              this.props.updateEscalation({
                schemeSrn: escalation.get('srn'),
                ...params,
              })
            }
            filters={escalation.get('filters')}
            saving={this.props.updating}
          />
        )}
        {this.state.ruleModalOpen && (
          <EditRulesModal
            close={this.closeRuleModal}
            isOpen
            update={params =>
              this.props.updateEscalation({
                schemeSrn: escalation.get('srn'),
                ...params,
              })
            }
            rules={escalation.get('rules')}
            saving={this.props.updating}
          />
        )}
      </div>
    )
  }
}

EscalationDetails.propTypes = {
  location: PropTypes.object,
  getEscalations: PropTypes.func,
  push: PropTypes.func,
  userHasPermission: PropTypes.func,
  fetchingPolicies: PropTypes.bool,
  updateEscalation: PropTypes.func,
  escalations: ImmutablePropTypes.map,
  controlFrameworks: ImmutablePropTypes.map,
  policies: ImmutablePropTypes.map,
  swimlanes: ImmutablePropTypes.map,
  loading: PropTypes.bool,
  swimlanesLoading: PropTypes.bool,
  updating: PropTypes.bool,
  fetchingCFs: PropTypes.bool,
  theme: themeShape,
  updatingError: PropTypes.string,
  errorInvalidSwimlanes: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  clearErrorInvalidSwimlanes: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  escalations: selectEscalations,
  loading: selectEscalationsLoading,
  swimlanes: selectSwimlanes,
  swimlanesLoading: selectSwimlanesLoading,
  updating: selectUpdatingEscalation,
  updatingError: selectUpdatingEscalationError,
  policies: selectPolicies,
  controlFrameworks: selectControlGroups,
  fetchingPolicies: selectFetchingPolicies,
  fetchingCFs: selectFetchingControlGroups,
  errorInvalidSwimlanes: selectUpdatingEscalationInvalidSwimlaneForTickets,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEscalations,
      updateEscalation,
      clearErrorInvalidSwimlanes: clearUpdateEscalationSwimlaneEditCustomTicketError,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withConnect,
  themeable,
  permissionChecker
)(EscalationDetails)
